
import OwlCarousel from 'react-owl-carousel'
import Arrow from '../Arrow'



export default function HomeGallery({ withoutTitle }) {
    return <section className="portfolio section" style={{ position: "relative", marginTop: "-60px", padding: withoutTitle ? "5px 0px" : "100px 0" }}>
        <i className="mobileArrows fa fa-chevron-right chevron1"></i>
        <i className="mobileArrows fa fa-chevron-right chevron2"></i>
        <i className="mobileArrows fa fa-chevron-right chevron3"></i>
        {
            withoutTitle ? '' : <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2>Bahçelievler Aile Destek Evi Galeri</h2>
                            {/* <img src={require("../../img/section-img.png")} alt="#" style={{height: "350px"}}/> */}
                            <p>
                                Aile Destek Evi fotoğraf galerisi
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <Arrow style={{ position: "absolute", width: "20px", height: "20px", right: "65px", top: "20px", overflow: "visible", transform: "translate(0%, 0) rotate(180deg)", zIndex: 999 }} />
                    <OwlCarousel className="owl-carousel portfolio-slider" id="photo-gallery-index" autoplay={true} autoplayTimeout={4000} margin={15} smartSpeed={300} autoplayHoverPause={true} loop={true} nav={true} dots={false} responsive={{
                        300: {
                            items: 1
                        },
                        480: {
                            items: 2
                        },
                        768: {
                            items: 2
                        },
                        1170: {
                            items: 4
                        }
                    }}>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/kiyafet_yardim.png")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.06 (1).jpeg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../haber1.JPG")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.07.jpeg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../haber3.JPG")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/kiyafet_yardim (2).jpeg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.07 (2).jpeg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>


                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.07 (1).jpeg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>

                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (23)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.06.jpeg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (24)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (26)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (27)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (28)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (29)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (30)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (31)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (37)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../kiyafet/kiyafet_yardim (38)-min.jpg")} alt="#" style={{ height: "350px", objectFit: "cover" }} />
                        </div>

                    </OwlCarousel>
                </div>
            </div>
        </div>
    </section >
}