import OwlCarousel from 'react-owl-carousel'




export default function Content() {

    return <section className="pf-details section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="inner-content">
                        <div className="image-slider">
                            <OwlCarousel className="pf-details-slider" style={{ height: "450px", overflow: "hidden" }} items={1} nav={true} loop={true} navText={['<span class="left-btn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>', '<span class="right-btn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>']} autoplay={true} smartSpeed={700} autoplayHoverPause={true} autoplaySpeed={1000}>
                                <img src={require("../../slide (1).jpeg")} className='item' alt="#" style={{ objectFit: "fill" }} />
                                <img src={require("../../slide (2).jpeg")} className='item' alt="#" style={{ objectFit: "fill" }} />
                            </OwlCarousel>
                        </div>
                        {/* <div className="date">
                            <ul>
                                <li><span>Hizmet Adı:</span> Kardeş Aile</li>
                                <li><span>Süre:</span> Yok</li>

                            </ul>
                        </div> */}
                        <div className="body-text">
                            <h3>Kardeş Aile Projesi</h3>
                            <p>İhtiyaçlı vatandaşlara yardımda bulunmak isteyen kişiler kurumumuza başvurur yardım
                                yapmak istediği kişinin durumunu seçer(şehit ailesi, yetim, özel gereksinimli) bilgi
                                işlem biriminde kayıt bilgileri bulunan kişilerden uygun olan kişi çağrılır ve yardım
                                yapmak isteyen şahısla buluşturulur. Kurumumuz bu kişiler arasında aracılık görevi
                                üstlenmektedir.</p>
                            <div className="share">
                                <h4>Paylaş -</h4>
                                <ul>
                                    <li><a href="#" data-href="https://www.facebook.com/sharer/sharer.php?u="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-facebook-official"
                                            aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://twitter.com/intent/tweet?text=@BahcelievlerBel&url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://www.linkedin.com/sharing/share-offsite/?url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}><i
                                            className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}