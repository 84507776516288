import KiyafetContainer from "./KiyafetContainer";
import MobilyaContainer from "./MobilyaContainer";
import VideoContainer from "./VideoContainer";





export default function Container({ settings }) {


    return <>
        <KiyafetContainer />
        <MobilyaContainer />
        <VideoContainer />
    </>
}