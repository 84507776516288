

import React from "react";



export default class NavSimulation extends React.Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {}

    }

    componentDidUpdate(state) {
        if (state !== this.state) return;
    }
    render() {
        return this.props.navSettings ? this.props.navSettings.sort((a, b) => a.position < b.position).map(nav => {
            return nav.active ? <div key={nav.position+nav.ad}>
                <a key={nav.position} href={`${(!nav.click || typeof (nav.click) == "object") ? `#${nav.ad}` : `#${nav.click}`}`} style={{ padding: "6px 0", fontWeight: "bold" }} onClick={() => {
                    this.setState({
                        [nav.ad]: this.state[nav.ad] ? !this.state[nav.ad] : true
                    })
                    if (nav.click && !(nav.click.startsWith("#") || nav.click.startsWith("http"))) {
                        this.props.setPage(nav.click);
                        this.props.setOpenNav(false)
                    }
                }}>{nav.ad} {((nav.altKategoriler != null && typeof nav.altKategoriler !== "object") && JSON.parse(nav.altKategoriler).length > 0) ? <i className="fa fa-chevron-right" style={{ fontSize: "9px", width: "10px", height: "10px", borderRadius: "50%", transition: "all .2s linear", rotate: this.state[nav.ad] ? '90deg' : '0deg' }}></i> : null}</a>
                {
                    typeof nav.altKategoriler == "string" && JSON.parse(nav.altKategoriler).length > 0 ?
                        <div className="subnavmenu" style={{ height: this.state[nav.ad] ? `${JSON.parse(nav.altKategoriler).length * 35}px` : "0", padding: this.state[nav.ad] ? "5px 0 0 15px" : "0 0 0 15px", overflow: "hidden", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", transition: "all .2s linear" }}>

                            {
                                typeof nav.altKategoriler == "string" ? JSON.parse(nav.altKategoriler).sort((a, b) => a.position < b.position).map(altNav => {

                                    return <a key={altNav.position + " altNav"} href="#" style={{ padding: "6px 0" }} onClick={() => {
                                        if (typeof altNav.click == "string" && !altNav.click.startsWith("http")) {
                                            this.props.setOpenNav(false)
                                            this.props.setPage(altNav.click)
                                        }
                                    }}>{altNav.ad}</a>

                                }) : null
                            }
                        </div>
                        : null
                }
            </div> : null
        }) : null
    }

}