import './App.css';
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/responsive.css";
import "./css/slicknav.min.css";
import "./css/owl-carousel.css";
import "./css/normalize.css";
import "./css/nice-select.css";
import "./css/magnific-popup.css";
import "./css/icofont.css";
import "./css/font-awesome.min.css";
import "./css/datepicker.css";
import "./css/animate.min.css";
import Header from './Components/Header';
import { useEffect, useState } from 'react';
import Main from './Components/Main';
import Preloader from './Components/Preloader';
import Footer from './Components/Footer';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Container from './Components/Sliders/MainSlider';
import axios from 'axios';
import { API } from '.';

function App() {
  const [page, setPage] = useState("anasayfa");
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    API.get("Global/GetSiteSettings").then(response => {
      setSettings(prev => {
        return {
          ...prev,
          siteAyar: response.data
        }
      })
    }).catch(err => { }).finally(() => {
      API.get("Global/GetSlaytSettings").then(response => {
        setSettings(prev => {
          return {
            ...prev,
            slayt: response.data
          }
        })
      }).catch(err => { }).finally(() => {
        API.get("Global/GetBaskanSettings").then(response => {
          setSettings(prev => {
            return {
              ...prev,
              baskan: response.data
            }
          })
        }).catch(err => { }).finally(() => {
          API.get("Global/GetKartlarSettings").then(response => {
            setSettings(prev => {
              return {
                ...prev,
                kart: response.data
              }
            })
          }).catch(err => { }).finally(() => {
            API.get("Global/GetBagiscilarSettings").then(response => {
              setSettings(prev => {
                return {
                  ...prev,
                  bagiscilar: response.data
                }
              })
            }).catch(err => { }).finally(() => {
              API.get("Global/GetHakkimizdaSettings").then(response => {
                setSettings(prev => {
                  return {
                    ...prev,
                    hakkimizda: response.data
                  }
                })
              }).catch(err => { }).finally(() => {
                API.get("Global/GetBizeUlasinSettings").then(response => {
                  setSettings(prev => {
                    return {
                      ...prev,
                      bizeUlasin: response.data
                    }
                  })
                }).catch(err => { }).finally(() => {
                  API.get("Global/GetHaberlerSettings").then(response => {
                    setSettings(prev => {
                      return {
                        ...prev,
                        haberler: response.data
                      }
                    })
                  }).catch(err => { }).finally(() => {
                    setLoading(false)
                  })
                })
              })
            })
          })
        })
      })
    })
  }, [])
  const state = {

  }

  return loading ? <Preloader /> : <>
    <Container />
    <Preloader page={page} />
    <Header page={page} setPage={setPage} state={state} settings={settings} />
    <Main page={page} setPage={setPage} settings={settings} />
    <Footer page={page} setPage={setPage} settings={settings} />
  </>

}

export default App;
