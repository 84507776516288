import Breadcumb from "../Components/MobilyaYardim/Breadcumb"
import Content from "../Components/MobilyaYardim/Content"
import Unavailable from "../Components/Unavailable"



export default function MobilyaYardim({ page, setPage }) {
    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content />
    </>
}