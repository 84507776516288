export default function Content({
    settings
}) {

    return <section className="pf-details section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="inner-content">
                        <div className="body-text">
                            {
                                settings.hakkimizda.filter(x => x.yayinlansinMi).map(t => {
                                    return <p key={t.id} style={{ fontSize: "18px", textAlign: "center", color: "black" }}>
                                        {t.icerik}
                                    </p>
                                })
                            }
                            {/* <p style={{ fontSize: "18px", textAlign: "center", color: "black" }}>
                                Bahçelievler Aile Destek Evi Birimi kurulduğu günden bu yana desteğe ihtiyacı olan aileler ile yardımsever kişi, kurum ve kuruluşlar arasında dayanışma köprüsü oluşturmuştur.
                                <br />
                                Gönüllülük esasıyla kurduğumuz bu bağı her geçen gün güçlendirmeye devam ediyoruz.
                            </p> */}
                            <div className="share">
                                <h4>Paylaş -</h4>
                                <ul>
                                    <li><a href="#" data-href="https://www.facebook.com/sharer/sharer.php?u="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-facebook-official"
                                            aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://twitter.com/intent/tweet?text=@BahcelievlerBel&url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://www.linkedin.com/sharing/share-offsite/?url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}><i
                                            className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}