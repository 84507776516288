





import React from 'react';



export default class NavigationSimulator extends React.Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {}
    }

    render() {
        return this.props.navSettings ? this.props.navSettings.sort((a, b) => a.position < b.position).map(nav => {

            if (typeof nav.altKategoriler == "string") {
                var katAdlari = [];
                JSON.parse(nav.altKategoriler).forEach(alt => {
                    katAdlari.push(alt.click);
                })
                if (!nav.isActive) return null;
                return <li className={`${katAdlari.includes(this.props.page) ? "active" : ""}`} key={nav.position+nav.ad}><a href="#">{nav.ad} <i className="icofont-rounded-down"></i></a>
                    <ul className="dropdown">
                        {
                            JSON.parse(nav.altKategoriler).sort((a, b) => a.position < b.position).map(altNav => {
                                return <li key={altNav.position + " altNavs"}><a href="#" onClick={() => this.props.setPage(altNav.click)}>{altNav.ad}</a></li>
                            })
                        }
                    </ul>
                </li>
            } else {
                if (!nav.isActive) return null;
                return <li className={`${this.props.page == nav.click ? 'active' : ''}`} key={nav.position}>
                    <a href="#" onClick={() => this.props.setPage(nav.click)}>{nav.ad}</a>
                </li>
            }
        })
            : null
    }
}