



export default function HaberOzet({ haber, setNews, setPage }) {
    return <div className="col-lg-4 col-md-6 col-12" style={{ height: "469px" }}>
        <div className="single-news" onClick={() => {
            setNews(haber)
            setPage("haber")
        }} style={{ height: "469px", cursor: "pointer", overflow: "hidden" }} >
            <div className="news-head">
                <img src={haber.src} alt="#" style={{height: "200px", objectFit: "cover"}}/>
            </div>
            <div className="news-body">
                <div className="news-content" style={{ height: "245px" }}>
                    <div className="date">{haber.tarih}</div>
                    <h2>
                        <a href="#1">{haber.baslik}</a>
                    </h2>
                    <p className="text">
                        {haber.icerik.slice(0, 200)}...
                    </p>
                </div>
            </div>
        </div>
    </div>
}