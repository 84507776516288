





export default function Breadcumb({ page, setPage }) {


    return <div className="breadcrumbs overlay kardes-bread br5">
        <div className="container">
            <div className="bread-inner">
                <div className="row">
                    <div className="col-12">
                        <h2>Haberler</h2>
                        <ul className="bread-list">
                            <li><a href="#" onClick={(e) => setPage("anasayfa")}>Anasayfa</a></li>
                            <li><i className="icofont-simple-right"></i></li>
                            <li><a href="#">Haberler</a></li>
                            <li><i className="icofont-simple-right"></i></li>
                            <li>Haberler Tümü</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}