

import OnlyMobileSlider from "../Components/Home/OnlyMobileSlider";
import HomeSlider from "../Components/Home/HomeSlider";
import HomeCards from "../Components/Home/HomeCards";
import HomeVideo from "../Components/Home/HomeVideo";
import FunFacts from "../Components/Home/FunFacts";
import HomeAlways from "../Components/Home/HomeAlways";
import HomeHelp from "../Components/Home/HomeHelp";
import HomeGallery from "../Components/Home/HomeGallery";
import HomeNews from "../Components/Home/HomeNews";
import { useEffect, useState } from "react";
import Overlay from "../Components/Overlay";
import HomeContact from "../Components/Home/HomeContact";
import Unavailable from "../Components/Unavailable";



export default function Anasayfa({ page, setPage, setNews, settings }) {
    useEffect(() => {
    }, [])
    return <>
        {settings.siteAyar.anasayfaOnlyMobileSlider ? <OnlyMobileSlider /> : null}
        {settings.siteAyar.anasayfaSlayt ? <HomeSlider page={page} setPage={setPage} settings={settings}/> : null}
        {settings.siteAyar.anasayfaTanitim ? window.innerWidth < 767 ? <HomeVideo page={page} setPage={setPage} withoutTitle={true} /> : null : null}
        {settings.siteAyar.anasayfaGaleri ? window.innerWidth < 767 ? <HomeGallery withoutTitle={true} /> : null : null}
        {settings.siteAyar.anasayfaGaleri ? window.innerWidth > 767 ? <HomeGallery withoutTitle={true} /> : null : null}
        {settings.siteAyar.anasayfaKartlar ? <HomeCards page={page} setPage={setPage} settings={settings} /> : null}
        {settings.siteAyar.anasayfaTanitim ? window.innerWidth > 767 ? <HomeVideo page={page} setPage={setPage} withoutTitle={false} /> : null : null}
        {settings.siteAyar.anasayfaSayisalBilgiler ? <FunFacts /> : null}
        {settings.siteAyar.anasayfaHerZamanYanınızda ? <HomeAlways /> : null}
        {settings.siteAyar.anasayfaBasvuru ? <HomeHelp page={page} setPage={setPage} /> : null}
        {settings.siteAyar.anasayfaHaberler ? <HomeNews page={page} setPage={setPage} setNews={setNews} /> : null}
        {settings.siteAyar.anasayfaBizeUlasin ? <HomeContact /> : null}
    </>
}