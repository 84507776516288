export default function Footer({ page, setPage, settings }) {
    return <footer id="footer" className="footer">
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-footer">
                            <h2>BADE</h2>
                            <p>
                                Bahçelievler Belediyesi Sosyal Medya Hesapları
                            </p>
                            <ul className="social">
                                <li>
                                    <a href={settings.siteAyar.facebook}><i
                                        className="icofont-facebook"></i></a>
                                </li>

                                <li>
                                    <a href={settings.siteAyar.twitter}><i className="icofont-twitter"></i></a>
                                </li>
                                <li>
                                    <a href={settings.siteAyar.instagram}><i className="icofont-instagram"></i></a>
                                </li>
                                <li>
                                    <a href={settings.siteAyar.youtube}><i className="fa fa-youtube-play"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-footer f-link">
                            <h2>Hızlı Bağlantılar</h2>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <ul>
                                        {settings.siteAyar.anasayfa ? <li>
                                            <a href="#anasayfa" onClick={() => setPage("anasayfa")}><i className="fa fa-caret-right" aria-hidden="true"></i>Anasayfa</a>
                                        </li> : null}
                                        {
                                            settings.siteAyar.hakkimizda ? <li>
                                                <a href="#hakkimizda" onClick={() => setPage("hakkimizda")}><i className="fa fa-caret-right" aria-hidden="true"></i>Hakkımızda</a>
                                            </li> : null
                                        }
                                        {
                                            settings.siteAyar.kiyafetYardimi ? <li>
                                                <a href="#kardes-aile" onClick={() => setPage("kiyafetYardimi")}><i className="fa fa-caret-right" aria-hidden="true"></i>Hizmetler</a>
                                            </li> : null
                                        }
                                        {
                                            settings.siteAyar.galeriKiyafet ? <li>
                                                <a href="#galeri-kiyafet" onClick={() => setPage("galeriKiyafet")}><i className="fa fa-caret-right" aria-hidden="true"></i>Galeri</a>
                                            </li> : null
                                        }
                                        {
                                            settings.siteAyar.galeriVideo ? <li>
                                                <a href="#videolar" onClick={() => setPage("galeriVideo")}><i className="fa fa-caret-right" aria-hidden="true"></i>Videolar</a>
                                            </li> : null
                                        }
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <ul>
                                        {
                                            settings.siteAyar.bagiscilar ? <li>
                                                <a href="#bagiscilar" onClick={() => setPage("bagiscilar")}><i className="fa fa-caret-right" aria-hidden="true"></i>Bağışçılar</a>
                                            </li> : null
                                        }
                                        {
                                            settings.siteAyar.haberler ? <li>
                                                <a href="#haberler" onClick={() => setPage("haberler")}><i className="fa fa-caret-right" aria-hidden="true"></i>Haberler</a>
                                            </li> : null
                                        }
                                        <li>
                                            <a href="https://bahcelievler.istanbul"><i className="fa fa-caret-right"
                                                aria-hidden="true"></i>Bahçelievler</a>
                                        </li>
                                        {
                                            settings.siteAyar.baskan ? <li>
                                                <a href="#baskan" onClick={() => setPage("baskan")}><i className="fa fa-caret-right" aria-hidden="true"></i>Başkan</a>
                                            </li> : null
                                        }
                                        <li>
                                            <a href="#"><i className="fa fa-caret-right" aria-hidden="true"></i>Diğer</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-footer">
                            <h2>Yardım Başvurusu</h2>
                            <p>
                                Yardım Başvurusu için aşağıdaki linkleri de kullanabilirsiniz
                            </p>
                            <ul className="time-sidual" style={{ marginTop: "26px" }}>
                                {
                                    settings.siteAyar.bagisYap ? <li><a href="#bagis-yap" onClick={() => setPage("bagisYap")} style={{ fontWeight: "700" }}>Yardım Etmek İstiyorum</a></li> : null
                                }
                                <br />
                                {
                                    settings.siteAyar.bizeUlasin ? <li><a href="#bizeUlasin" onClick={() => setPage("bizeUlasin")} style={{ fontWeight: "700" }}>Yardım Almak İstiyorum</a></li> : null
                                }
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="copyright-content">
                            <p>© Copyright 2023 | <a href="https://bahcelievler.istanbul" target="_blank">Bahçelievler
                                Belediyesi Bilgi İşlem Müdürlüğü</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}