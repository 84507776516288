





export default function MobilyaContainer() {



    return <div id="mobilya-slide">
        <div className="mobilya-slide-kapat"><i className="icofont-close"></i> Kapat</div>
        <i className="icofont-arrow-left mobilya-slide-left"></i>
        <i className="icofont-arrow-right mobilya-slide-right"></i>
        <img id="mobilya-slide-img" src="#" alt="#" />
    </div>
}