import Breadcumb from "../Components/KiyafetYardim/Breadcumb";
import Content from "../Components/KiyafetYardim/Content";
import Unavailable from "../Components/Unavailable";




export default function KiyafetYardim({ page, setPage }) {
    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content />
    </>
}