import Anasayfa from "../Pages/Anasayfa"
import Hakkimizda from "../Pages/Hakkimizda"
import KardesAile from "../Pages/KardesAile"
import KiyafetYardim from "../Pages/KiyafetYardim"
import MobilyaYardim from "../Pages/MobilyaYardim"
import Baskan from '../Pages/Baskan'
import BizeUlasin from '../Pages/BizeUlasin'
import BagisYap from '../Pages/BagisYap'
import Bagiscilar from '../Pages/Bagiscilar'
import GaleriKiyafet from "../Pages/GaleriKiyafet"
import GaleriMobilya from "../Pages/GaleriMobilya"
import GaleriVideolar from "../Pages/GaleriVideolar"
import Haber from "../Pages/Haber"
import { useEffect, useState } from "react"
import Haberler from "../Pages/Haberler"
import axios from "axios"

export default function Main({ setPage, page, settings }) {
    const [news, setNews] = useState({});

    useEffect(() => {
    }, [news])

    const pages = {
        "anasayfa": <Anasayfa setPage={setPage} page={page} setNews={setNews} settings={settings} />,
        "kardesAile": <KardesAile page={page} setPage={setPage} settings={settings} />,
        "mobilyaYardimi": <MobilyaYardim page={page} setPage={setPage} settings={settings} />,
        "kiyafetYardimi": <KiyafetYardim page={page} setPage={setPage} settings={settings} />,
        "hakkimizda": <Hakkimizda page={page} setPage={setPage} settings={settings} />,
        "baskan": <Baskan page={page} setPage={setPage} settings={settings} />,
        "bizeUlasin": <BizeUlasin page={page} setPage={setPage} settings={settings} />,
        "bagisYap": <BagisYap page={page} setPage={setPage} settings={settings} />,
        "bagiscilar": <Bagiscilar page={page} setPage={setPage} settings={settings} />,
        "galeriKiyafet": <GaleriKiyafet page={page} setPage={setPage} settings={settings} />,
        "galeriMobilya": <GaleriMobilya page={page} setPage={setPage} settings={settings} />,
        "galeriVideo": <GaleriVideolar page={page} setPage={setPage} settings={settings} />,
        "haber": <Haber page={page} setPage={setPage} haber={news} settings={settings} />,
        "haberler": <Haberler page={page} setPage={setPage} setNews={setNews} settings={settings} />
    }
    return pages[page]
}