




export default function Arrow({ style }) {
    return <div id="arrowAnim" style={style}>
        <div className="arrowSliding">
            <div className="arrow"></div>
        </div>
        <div className="arrowSliding delay1">
            <div className="arrow"></div>
        </div>
        <div className="arrowSliding delay2">
            <div className="arrow"></div>
        </div>
        <div className="arrowSliding delay3">
            <div className="arrow"></div>
        </div>
    </div>
}