export default function Content() {

    return <section className="contact-us section">
        <div className="container">
            <div className="inner">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact-us-form" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <h2>Yardımsever Başvurusu</h2>
                            <p>Bu formu doldurduktan sonra sizinle iletişime geçilecektir.</p>
                            <iframe frameBorder="0" title="BaşvuruF" className="border-2 mt-1" scrolling="no"
                                width="100%" src="https://ulakbel.bahcelievler.bel.tr/WebBasvuru#/" id="ubel"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}