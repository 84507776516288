import { useEffect, useState } from "react"





export default function HomeCards({ page, setPage, settings }) {

    const [cards, setCards] = useState();
    useEffect(() => {
        if (cards != null) return;
        setCards(settings.kart)
    }, [cards])
    return <section className="schedule">
        <div className="container">
            <div className="schedule-inner d-flex flex-wrap">
                {
                    cards ? cards.map(t => {
                        if(!t.isActive) return;
                        return <div className="col-lg-6 col-md-6 col-12" key={t.id}>
                            <div className="single-schedule middle">
                                <div className="inner">
                                    <div className="icon">
                                        <i className={`icofont-${t.ikon}`}></i>
                                    </div>
                                    <div className="single-content">
                                        <span>{t.category}</span>
                                        <h4>{t.title}</h4>
                                        <p>
                                            {t.description}
                                        </p>
                                        <a href={`#${t.moreClick}`} onClick={() => setPage(t.moreClick)}>{t.moreText}<i className="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : null
                }
            </div>
        </div>

    </section >
}