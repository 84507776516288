import axios from "axios";
import { useEffect, useState } from "react"





export default function Content() {

    useEffect(() => {
        let tempArr = [];

        var videos = document.getElementsByClassName("video-img-inner");
        Array.from(videos).forEach(el => {
            var src = el.getAttribute("src");
            tempArr.push(src);
        })


        var videoClicks = document.getElementsByClassName("video-img");
        Array.from(videoClicks).forEach(el => {
            el.addEventListener("click", async (e) => {
                var src = e.currentTarget.childNodes[0].childNodes[0].getAttribute("src");
                document.body.style.overflow = "hidden";
                var slideVideo = document.getElementById("video-slide");
                slideVideo.style.display = "flex";
                var videoPanel = document.getElementById("video-panel");
                videoPanel.pause()
                var videoInside = document.getElementById("video-slide-img");
                videoInside.setAttribute("src", src);
                videoPanel.load();
                await videoPanel.play();
            })
        })

        var videoSlideKapat = document.getElementsByClassName("video-slide-kapat");
        videoSlideKapat[0].addEventListener("click", (e) => {
            document.body.style.overflow = "unset";
            var slideVideo = document.getElementById("video-slide");
            slideVideo.style.display = "none";
        })

        var leftBtn = document.getElementsByClassName("video-slide-left");
        leftBtn[0].addEventListener("click", async (e) => {
            var videoInside = document.getElementById("video-slide-img");
            var src = videoInside.getAttribute("src");
            var indexOfSrc = tempArr.indexOf(src);
            var videoPanel = document.getElementById("video-panel");
            videoPanel.pause();
            if (indexOfSrc == 0) {
                videoInside.setAttribute("src", tempArr[tempArr.length - 1])
                videoPanel.load();
                await videoPanel.play();
            } else {
                videoInside.setAttribute("src", tempArr[indexOfSrc - 1])
                videoPanel.load();
                await videoPanel.play();
            }
        })


        var rightBtn = document.getElementsByClassName("video-slide-right");
        rightBtn[0].addEventListener("click", async (e) => {
            var videoInside = document.getElementById("video-slide-img");
            var src = videoInside.getAttribute("src");
            var indexOfSrc = tempArr.indexOf(src);
            var videoPanel = document.getElementById("video-panel");
            videoPanel.pause();
            if (indexOfSrc == tempArr.length - 1) {
                videoInside.setAttribute("src", tempArr[0])
                videoPanel.load();
                await videoPanel.play();
            } else {
                videoInside.setAttribute("src", tempArr[indexOfSrc + 1])
                videoPanel.load();
                await videoPanel.play();
            }
        })

    }, [])

    return <section className="pf-details section galeri-sect">
        <div className="container">
            <div className="row">
                <div className="col-12 donater-container video-container" id="video">
                    <div className="video-img"><video style={{ width: "100%", height: "100%" }}><source src={require(`../../../video/bade_esya.mp4`)} className="video-img-inner" /></video></div>
                    <div className="video-img"><video style={{ width: "100%", height: "100%" }}><source src={require(`../../../video/bade_magaza.mp4`)} className="video-img-inner" /></video></div>
                    <div className="video-img"><video style={{ width: "100%", height: "100%" }}><source src={require(`../../../video/bade_New.mp4`)} className="video-img-inner" /></video></div>
                    <div className="video-img"><video style={{ width: "100%", height: "100%" }}><source src={require(`../../../video/bade.mp4`)} className="video-img-inner" /></video></div>
                </div>
            </div>
        </div>
    </section>
}