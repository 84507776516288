import Breadcumb from "../Components/BizeUlasin/Breadcumb";
import Content from "../Components/BizeUlasin/Content";
import Unavailable from "../Components/Unavailable";





export default function BizeUlasin({ page, setPage, settings }) {

    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content settings={settings} />
    </>
}