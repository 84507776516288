import Breadcumb from "../Components/Haber/Breadcumb";
import Content from "../Components/Haber/Content";






export default function Haber({ page, setPage, haber }) {



    return <>
        <Breadcumb page={page} setPage={setPage} haberBaslik={haber.baslik} />
        <Content haber={haber} />
    </>
}