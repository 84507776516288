import Breadcumb from "../Components/Hakkimizda/Breadcumb";
import Content from "../Components/Hakkimizda/Content";
import Unavailable from "../Components/Unavailable";




export default function Hakkimizda({ page, setPage, settings }) {

    return <>
        <Breadcumb setPage={setPage} page={page} />
        <Content settings={settings} />
    </>


}