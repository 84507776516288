import OwlCarousel from 'react-owl-carousel'
import badeVid from '../../video/bade_esya.mp4'



export default function Content() {

    return <section className="pf-details section" style={{ paddingTop: "10px" }}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="inner-content">
                        <div className="image-slider" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <video controls muted style={{ width: "75%" }}>
                                <source src={badeVid} />
                            </video>
                        </div>
                        {/* <div className="date">
                            <ul>
                                <li><span>Hizmet Adı :</span> Mobilya Yardımı</li>
                                <li><span>Süre :</span> Yok</li>
                                <li><span>Yer :</span> Bahçelievler Aile Destek Evi Merkezi</li>
                            </ul>
                        </div> */}
                        <div className="body-text">
                            <h3>Eşya Yardımı</h3>
                            <p>Yeni-Kullanılmamış ya da kullanılabilir durumda olan tadilat gerektirmeyen tüm ev eşyalarınızı bağış yapmak istediğinizde adresinizden alıp, ailelerimize ulaştırıyoruz.<br />
                                <br />
                                Bahçelievler Belediyesi ilçedeki komşuların dezavatanjlarını ihtiyaçlarını dikkate alarak hayata geçirdiği yardım çalışmalarıyla refahın tüm ilçede eşit şekilde yaygınlaşması için çalışıyor.
                                <br />
                                Müdürlüğümüz tarafından sosyo-ekonomik durumları araştırılıp uygunluğu tespit edilen aileler bu hizmetten faydalanmaktadır.
                                <br />
                                <br />
                                Desteğe ihtiyacı olan vatandaşlarımız <a href="tel:4440311" style={{ color: "#1a76d1" }}>444 0 311</a> numaralı çağrı merkezimizi arayarak başvuru yapabilir.
                            </p>
                            <div className="share">
                                <h4>Paylaş -</h4>
                                <ul>
                                    <li><a href="#" data-href="https://www.facebook.com/sharer/sharer.php?u="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-facebook-official"
                                            aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://twitter.com/intent/tweet?text=@BahcelievlerBel&url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://www.linkedin.com/sharing/share-offsite/?url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}><i
                                            className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}