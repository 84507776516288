import Breadcumb from "../Components/Galeri/Videolar/Breadcumb";
import Content from "../Components/Galeri/Videolar/Content";
import Unavailable from "../Components/Unavailable";






export default function GaleriVideolar({ page, setPage }) {


    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content />
    </>
}