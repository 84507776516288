
import OwlCarousel from 'react-owl-carousel'
import Arrow from '../Arrow'
import { useEffect, useState } from 'react'

export default function HomeSlider({ setPage, settings }) {
    const [sliders, setSliders] = useState();
    useEffect(() => {
        if (sliders != null) return;
        setSliders(settings.slayt)
    }, [sliders])
    return <section className="slider" style={{ position: "relative" }}>
        <i className="mobileArrows fa fa-chevron-right chevron1"></i>
        <i className="mobileArrows fa fa-chevron-right chevron2"></i>
        <i className="mobileArrows fa fa-chevron-right chevron3"></i>
        <Arrow style={{ position: "absolute", width: "50px", height: "30px", right: "25px", bottom: "-1px", overflow: "hidden", transform: "translate(0%, 0) rotate(180deg)" }} />
        {
            sliders ? <OwlCarousel items={1} loop={true} autoplay={true} smartSpeed={1500} autoplayTimeout={3500} autoplayHoverPause={true} nav={true} navText={['<span class="left-btn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>', '<span class="right-btn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>']} dots={false}>
                {
                    sliders ? sliders.map(t => {
                        if(!t.isActive) return;
                        return <div key={t.id} className="single-slider item">
                            <div className="container" style={{ position: "relative" }}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="text">
                                            <h1>
                                                <span>{t.firstBlueText}</span> {t.firstBlackText}
                                                <span> {t.secondBlueText}</span> {t.secondBlackText}
                                            </h1>
                                            <p>
                                                {t.descriptionText}
                                            </p>
                                            <div className="button">
                                                <a href={`#${JSON.parse(t.firstButton).click}`} onClick={() => setPage(JSON.parse(t.firstButton).click)} className={`btn ${JSON.parse(t.firstButton).bg == "#1a76d1" ? "primary" : null}`}>{JSON.parse(t.firstButton).text}</a>
                                                <a href={`#${JSON.parse(t.secondButton).click}`} onClick={() => setPage(JSON.parse(t.secondButton).click)} className={`btn ${JSON.parse(t.secondButton).bg == "#1a76d1" ? "primary" : null}`}>{JSON.parse(t.secondButton).text}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5"
                                        style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "100%", "marginTop": "45px" }}>
                                        <img src={t.photoURL} style={{ overflow: "hidden", objectFit: "contain", width: "460px", height: "400px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : null
                }
            </OwlCarousel> : null
        }
    </section>
}