


export default function Content({ haber }) {

    return <section className="pf-details section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="inner-content">
                        <div className="body-text">
                            {
                                haber.hasOwnProperty("icerikVideo") ? <video src={haber.icerikVideo} style={{ width: "100%", height: "500px", objectFit: "contain", objectPosition: "top" }} controls muted id="haber-img" /> : <img src={haber.src} style={{ width: "100%", height: "500px", objectFit: "cover", objectPosition: "top" }} id="haber-img" />
                            }
                            <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "10px" }}
                                className="haber-baslik">{haber.baslik}</h1>
                            <p style={{ textAlign: "justify", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px" }}
                                id="haber-icerik">
                                {haber.icerik}
                            </p>
                            <div className="share">
                                <h4>Paylaş -</h4>
                                <ul>
                                    <li><a href="#" data-href="https://www.facebook.com/sharer/sharer.php?u="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-facebook-official"
                                            aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://twitter.com/intent/tweet?text=@BahcelievlerBel&url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://www.linkedin.com/sharing/share-offsite/?url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}><i
                                            className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}