import { useEffect, useState } from "react"




export default function Preloader({ page }) {

    const [isActive, setIsActive] = useState(true);


    useEffect(() => {
        setIsActive(true);
        setTimeout(() => {
            setIsActive(false)
        }, 1000)
    }, [page])

    return <div className={`preloader ${isActive ? '' : 'preloader-deactivate'}`}>
        <div className="loader">
            <div className="loader-outter"></div>
            <div className="loader-inner"></div>
            <div className="indicator" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                <img src={require("../spinner_logo.png")} alt="#" style={{ width: "50px", height: "50px" }} />
            </div>
        </div>
    </div>
}