




export default function HomeVideo({ page, setPage, withoutTitle }) {
    function openVideo() {
        var el = document.getElementById("videopopup");
        var videoel = document.getElementById("popupvideo-bade");
        el.style.display = "flex";
    }
    function closeVideo() {
        var el = document.getElementById("videopopup");
        var videoel = document.getElementById("popupvideo-bade");
        el.style.display = "none";
    }
    return <section className="why-choose section" style={{ marginTop: "90px", padding: "5px 0px 50px 0px" }}>
        <div className="container">
            <div className="row">
                {
                    withoutTitle ? '' : <div className="col-lg-6 col-12">
                        <div className="choose-left">
                            <h3>BADE</h3>
                            <p>
                                İhtiyaç sahibi vatandaşlarımız için daha kaliteli bir yaşam oluşturabilmek için çeşitli projeler
                                düzenleyen bir kurumdur.
                            </p>
                            <p>
                                Bu projelerden bazıları aşağıda yer almaktadır;
                            </p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul className="list">
                                        <li>
                                            <i className="fa fa-caret-right"></i>Kıyafet Yardımı
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="list">
                                        <li>
                                            <i className="fa fa-caret-right"></i>Eşya Yardımı
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={`${withoutTitle ? 'col-lg-12 col-12 col-md-12' : 'col-lg-6 col-12'}`}>
                    <div className="choose-right">
                        <div className="video-image">
                            <div className="promo-video">
                                <div className="waves-block">
                                    <div className="waves wave-1"></div>
                                    <div className="waves wave-2"></div>
                                    <div className="waves wave-3"></div>
                                </div>
                            </div>
                            <div className="video video-popup mfp-iframe" onClick={() => openVideo()}><i className="fa fa-play"
                                style={{ cursor: "pointer" }}></i></div>
                            <div id="videopopup"
                                style={{ width: "100vw", height: "100vh", display: "none", justifyContent: "center", alignItems: "center", position: "fixed", top: "0", left: "0", background: "#000000c7", zIndex: "9999999" }}>
                                <div style={{ width: "50vw", height: "auto", aspectRatio: "2/1", position: "relative" }}>
                                    <div style={{ position: "absolute", top: "-50px", right: "0px", color: "white", cursor: "pointer" }}
                                        onClick={() => closeVideo()}>X Kapat
                                    </div>
                                    <video id="popupvideo-bade" preload="yes" controls loop playsInline muted autoPlay style={{ width: "100%", height: "100%", overflow: "visible", objectFit: "fill" }} poster={require("../../bade-banner1.png")} >
                                        {/* <source src={require("../../bade_New.mp4")} id="tanitimVideo" style={{ objectFit: "cover", width: "100%", height: "100%", border: "none", overflow: "visible" }} /> */}
                                        <source type="video/mp4" src={require("../../bade_New.mp4")} />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}