import Breadcumb from "../Components/Haberler/Breadcumb";
import Content from "../Components/Haberler/Content";
import Unavailable from "../Components/Unavailable";








export default function Haberler({ page, setPage, setNews }) {




    return <>
        <Breadcumb page={page} setPage={setPage} setNews={setNews} />
        <Content page={page} setPage={setPage} setNews={setNews} />
    </>
}