import { useEffect, useState } from "react"
import axios from 'axios';
import { API } from "../..";
export default function Content({settings}) {
    const [donaters, setDonaters] = useState();

    useEffect(() => {
        if(donaters != null) return;
        setDonaters(settings.bagiscilar);
    }, [donaters]) 

    return <section className="pf-details section">
        <div className="container">
            <div className="row">
                <div className="col-12 donater-container">


                    {
                        donaters ? donaters.map((bagisci, i) => {
                            if(!bagisci.yayinlansinMi) return;
                            return <div className="donater-card" key={i}>
                                <div className="donater-card-top"></div>
                                <div className="donater-card-main" style={{ position: "relative" }}>
                                    <div className="donater-card-image">
                                        <img
                                            src={bagisci.photo}
                                            alt="#" />
                                    </div>
                                    <div className="donater-card-name">{bagisci.ad}</div>
                                    <div className="donater-card-details">{bagisci.detaylar}
                                    </div>
                                    <div className="donater-card-socials" style={{ position: "absolute", bottom: "5px", left: "50%", transform: "translate(-50%, 0)" }}>
                                        {
                                            Object.keys(JSON.parse(bagisci.socials)).map((k, i) => {
                                                return <i key={i} className={k} onClick={(e) => { window.open(JSON.parse(bagisci.socials)[k], "_blank") }}></i>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }) : null
                    }



                </div>
            </div>
        </div>

    </section>
}