import { useEffect, useState } from "react";
import NavSimulation from "./NavSimulation";
import NavigationSimulator from "./NavigationSimulator";

const popUpWindow = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
        `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
    )

    if (window.focus) newWindow.focus();
}

export default function Header({ setPage, page, state, settings }) {


    const [openNav, setOpenNav] = useState(false);
    const [hakkimizdaMiniOpen, setHakkimizdaMiniOpen] = useState(false);
    const [hizmetlerMiniOpen, setHizmetlerMiniOpen] = useState(false);
    const [galeriMiniOpen, setGaleriMiniOpen] = useState(false);

    const topNavSettings = [
        {
            position: 0,
            ad: "Hakkımızda",
            click: "hakkimizda",
            isActive: settings.siteAyar.hakkimizda
        },
        {
            position: 1,
            ad: "Başkan",
            click: "baskan",
            isActive: settings.siteAyar.baskan,
        },
        {
            position: 2,
            ad: "Haberler",
            click: "haberler",
            isActive: settings.siteAyar.haberler,
        },
        {
            position: 3,
            ad: "Bize Ulaşın",
            click: "bizeUlasin",
            isActive: settings.siteAyar.bizeUlasin
        }
    ]
    useEffect(() => {

    }, [state])

    return <header className="header">
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-5 col-12">
                        <ul className="top-link">
                            {
                                topNavSettings ? (topNavSettings).sort((a, b) => a.position < b.position).map(topnav => {
                                    if (!topnav.isActive) return;
                                    return <li key={topnav.position}><a href={`#${topnav.click}`} onClick={() => setPage(topnav.click)}>{topnav.ad}</a></li>
                                }) : null
                            }
                            <li className="bagis-yap">
                                <a href="#bagis-yap" onClick={() => setPage("bagisYap")}>
                                    <i className="icofont-heart-alt" style={{ marginRight: "8px", marginTop: "4px" }}></i>
                                    Bağış Yap
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-7 col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ul className="top-contact">
                            <li><i className="fa fa-phone"></i><a href={`tel:${settings.siteAyar.numara}`}>
                                {settings.siteAyar.numara}
                            </a></li>
                            <li>
                                <i className="fa fa-envelope"></i><a href="mailto:iletisim@ailedestekevi.com">
                                    {settings.siteAyar.eposta}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="header-inner">
            <div className="container">
                <div className="inner">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12" style={{ position: "relative" }}>
                            <div className="logo">
                                <a href="#" onClick={() => setPage("anasayfa")}><img src={require("../img/logo.png")} className="imagelogo" alt="#"
                                    style={{ marginBottom: "2px" }} /></a>
                            </div>
                            <div className="mobile-nav-open" onClick={(e) => {
                                e.preventDefault();
                                setOpenNav(prev => !prev);
                            }}>
                                <i className="icofont-navigation-menu"></i>
                            </div>
                            <div className={openNav ? "mobile-nav active-nav" : "mobile-nav"} style={{ padding: openNav ? "10px 0 0 10px" : "0px" }}>
                                {
                                    settings.siteAyar.anasayfa ? <a href="#" onClick={() => {
                                        setPage("anasayfa")
                                        setHizmetlerMiniOpen(false)
                                        setGaleriMiniOpen(false)
                                        setHakkimizdaMiniOpen(false)
                                        setOpenNav(false)
                                    }} style={{ marginTop: "7px", padding: "6px 0", fontWeight: "bold" }} >Anasayfa</a> : null
                                }
                                <a href="#" onClick={() => setHizmetlerMiniOpen(prev => !prev)} style={{ marginTop: "7px", padding: "6px 0px", fontWeight: "bold" }}>Hizmetlerimiz <i className="fa fa-chevron-right" style={{ fontSize: "9px", width: "10px", height: "10px", borderRadius: "50%", transition: "all .2s linear", rotate: hizmetlerMiniOpen ? '90deg' : '0deg' }}></i></a>
                                <div className="subnavmenu" style={{ height: hizmetlerMiniOpen ? "114px" : "0", padding: hizmetlerMiniOpen ? "5px 0 0 15px" : "0 0 0 15px", overflow: "hidden", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", transition: "all .2s linear" }}>
                                    {
                                        settings.siteAyar.kardesAile ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("kardesAile")
                                            setHizmetlerMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setHakkimizdaMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Kardeş Aile Projesi</a> : null
                                    }
                                    {
                                        settings.siteAyar.kiyafetYardimi ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("kiyafetYardimi")
                                            setHizmetlerMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setHakkimizdaMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Kıyafet Yardımı</a> : null
                                    }
                                    {
                                        settings.siteAyar.mobilyaYardimi ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("mobilyaYardimi")
                                            setHizmetlerMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setHakkimizdaMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Eşya Yardımı</a>
                                            : null
                                    }
                                </div>
                                {
                                    settings.siteAyar.bagiscilar ? <a href="#" onClick={() => {
                                        setPage("bagiscilar")
                                        setHizmetlerMiniOpen(false)
                                        setGaleriMiniOpen(false)
                                        setHakkimizdaMiniOpen(false)
                                        setOpenNav(false)
                                    }} style={{ marginTop: "7px", padding: "6px 0", fontWeight: "bold" }} >Bağışçılarımız</a> : null
                                }
                                <a href="#" onClick={() => setGaleriMiniOpen(prev => !prev)} style={{ marginTop: "7px", padding: "6px 0px", fontWeight: "bold" }}>Galeri <i className="fa fa-chevron-right" style={{ fontSize: "9px", width: "10px", height: "10px", borderRadius: "50%", transition: "all .2s linear", rotate: galeriMiniOpen ? '90deg' : '0deg' }}></i></a>
                                <div className="subnavmenu" style={{ height: galeriMiniOpen ? "114px" : "0", padding: galeriMiniOpen ? "5px 0 0 15px" : "0 0 0 15px", overflow: "hidden", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", transition: "all .2s linear" }}>
                                    {
                                        settings.siteAyar.galeriKiyafet ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("galeriKiyafet")
                                            setGaleriMiniOpen(false)
                                            setHizmetlerMiniOpen(false)
                                            setHakkimizdaMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Kıyafet Yardımı</a> : null
                                    }
                                    {
                                        settings.siteAyar.galeriMobilya ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("galeriMobilya")
                                            setGaleriMiniOpen(false)
                                            setHizmetlerMiniOpen(false)
                                            setHakkimizdaMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Eşya Yardımı</a> : null
                                    }
                                    {
                                        settings.siteAyar.galeriVideo ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("galeriVideo")
                                            setHizmetlerMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setHakkimizdaMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Videolar</a> : null
                                    }
                                </div>
                                <a href="#" onClick={() => setHakkimizdaMiniOpen(prev => !prev)} style={{ marginTop: "7px", padding: "6px 0px", fontWeight: "bold" }}>Hakkımızda <i className="fa fa-chevron-right" style={{ fontSize: "9px", width: "10px", height: "10px", borderRadius: "50%", transition: "all .2s linear", rotate: hakkimizdaMiniOpen ? '90deg' : '0deg' }}></i></a>
                                <div className="subnavmenu" style={{ height: hakkimizdaMiniOpen ? "178px" : "0", padding: hakkimizdaMiniOpen ? "5px 0 0 15px" : "0 0 0 15px", overflow: "hidden", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", transition: "all .2s linear" }}>
                                    {
                                        settings.siteAyar.hakkimizda ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("hakkimizda")
                                            setHakkimizdaMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setHizmetlerMiniOpen(false)
                                            setOpenNav(false)
                                        }}>BADE Hakkında</a> : null
                                    }
                                    <a href="https://bahcelievler.istanbul" style={{ padding: "6px 0" }} target="_blank" onClick={() => {
                                        setHakkimizdaMiniOpen(false)
                                        setGaleriMiniOpen(false)
                                        setHizmetlerMiniOpen(false)
                                        setOpenNav(false)
                                    }}>Bahçelievler Belediyesi</a>
                                    {
                                        settings.siteAyar.baskan ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("baskan")
                                            setHakkimizdaMiniOpen(false)
                                            setHizmetlerMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Başkan</a> : null
                                    }
                                    {
                                        settings.siteAyar.bizeUlasin ? <a href="#" style={{ padding: "6px 0" }} onClick={() => {
                                            setPage("bizeUlasin")
                                            setHakkimizdaMiniOpen(false)
                                            setHizmetlerMiniOpen(false)
                                            setGaleriMiniOpen(false)
                                            setOpenNav(false)
                                        }}>Bize Ulaşın</a> : null
                                    }
                                    <a href="#footer" style={{ padding: "6px 0" }} onClick={() => {
                                        setHakkimizdaMiniOpen(false)
                                        setHizmetlerMiniOpen(false)
                                        setGaleriMiniOpen(false)
                                        setOpenNav(false)
                                    }}>Sosyal Medya</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-9 col-12">
                            <div className="main-menu">
                                <nav className="navigation">
                                    <ul className="nav menu">

                                        {
                                            settings.siteAyar.anasayfa ? <li className={`${page == "anasayfa" ? 'active' : ''}`}>
                                                <a href="#" onClick={() => setPage("anasayfa")}>Anasayfa</a>
                                            </li> : null
                                        }
                                        <li className={`${["kardesAile", "kiyafetYardimi", "mobilyaYardimi"].includes(page) ? "active" : ""}`}><a href="#">Hizmetlerimiz <i className="icofont-rounded-down"></i></a>
                                            <ul className="dropdown">
                                                {settings.siteAyar.kardesAile ? <li><a href="#kardes-aile" onClick={() => setPage("kardesAile")}>Kardeş Aile Projesi</a></li> : null}
                                                {settings.siteAyar.kiyafetYardimi ? <li><a href="#kiyafet-yardim" onClick={() => setPage("kiyafetYardimi")}>Kıyafet Yardımı</a></li> : null}
                                                {settings.siteAyar.mobilyaYardimi ? <li><a href="#mobilya-yardim" onClick={() => setPage("mobilyaYardimi")}>Eşya Yardımı</a></li> : null}
                                            </ul>
                                        </li>
                                        {settings.siteAyar.bagiscilar ? <li className={`${page == "bagiscilar" ? 'active' : ''}`} ><a href="#bagiscilar" onClick={() => setPage("bagiscilar")}>Bağışçılar</a></li> : null}
                                        <li className={`${["galeriKiyafet", "galeriMobilya", "galeriVideo"].includes(page) ? "active" : ""}`} >
                                            <a href="#galeri">Galeri <i className="icofont-rounded-down"></i></a>
                                            <ul className="dropdown">
                                                {settings.siteAyar.galeriKiyafet ? <li><a href="#galeri-kiyafet" onClick={() => setPage("galeriKiyafet")}>Kıyafet Yardımı</a></li> : null}
                                                {settings.siteAyar.galeriMobilya ? <li><a href="#galeri-mobilya" onClick={() => setPage("galeriMobilya")}>Eşya Yardımı</a></li> : null}
                                                {settings.siteAyar.galeriVideo ? <li><a href="#galeri-video" onClick={() => setPage("galeriVideo")}>Videolar</a></li> : null}
                                            </ul>
                                        </li>

                                        <li className={`${["hakkimizda", "baskan", "bizeUlasin"].includes(page) ? "active" : ""}`} >
                                            <a href="#hakkimizda" onClick={() => setPage("hakkimizda")}>Hakkımızda <i className="icofont-rounded-down"></i></a>
                                            <ul className="dropdown">
                                                {settings.siteAyar.hakkimizda ? <li><a href="#hakkimizda" onClick={() => setPage("hakkimizda")}>BADE Hakkında</a></li> : null}
                                                <li>
                                                    <a href="https://bahcelievler.istanbul" target="_blank">Bahçelievler
                                                        Belediyesi</a>
                                                </li>
                                                {settings.siteAyar.baskan ? <li><a href="#baskan" onClick={() => setPage("baskan")}>Başkan</a></li> : null}
                                                {settings.siteAyar.bizeUlasin ? <li><a href="#bize-ulasin" onClick={() => setPage("bizeUlasin")}>Bize Ulaşın</a></li> : null}
                                                <li className="footer-go"><a href="#footer">Sosyal Medya</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 cnwhbuttons"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px", color: "white", marginTop: "10px" }}>
                            {
                                settings.siteAyar.canliDestek ? <div className=""
                                    onClick={() => {
                                        popUpWindow({ url: 'https:\/\/canlidestek.bahcelievler.bel.tr:444/destek/?pageHost=www.bahcelievler.istanbul&pageUrl=https:\/\/www.bahcelievler.istanbul/&pageRef=&popup=true', title: 'Bahçelievler Canlı Destek Hattı', w: 400, h: 506 })
                                    }
                                    }>
                                    <a href="#" className="btn"
                                        style={{ position: "relative", display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "nowrap", gap: "8px" }}><svg
                                            xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512" fill="#ffffff">
                                            <path
                                                d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                                        </svg>Canlı Destek</a>
                                </div> : null
                            }
                            {
                                settings.siteAyar.whatsapp ? <div className='whatsapp-hat'
                                    style={{ fontSize: "13px", wordWrap: "nowrap", flexWrap: "nowrap", whiteSpace: "nowrap", color: "white", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 25px", width: "145.5px", borderRadius: "5px", backgroundColor: "#25d366" }}
                                >
                                    <a
                                        href={`https://api.whatsapp.com/send?phone=90${settings.siteAyar.numara.split(" ").join("")}`} target="_blank"
                                        style={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "nowrap", gap: "8px" }}>
                                        <i className="icofont-brand-whatsapp" style={{ fontSize: "18px", lineHeight: "18px", zIndex: 65 }} ></i>
                                        <span style={{ zIndex: 65 }}>Whatsapp Hattı</span>
                                    </a>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header >
}