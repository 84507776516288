



export default function HomeAlways() {
    return <section className="Feautes section" style={{ paddingTop: "25px" }}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>Her zaman sizin yanınızdayız!</h2>
                        {/* <img src={require("../../img/section-img.png")} alt="#" /> */}
                        <p>
                            Bahçelievler Aile Destek Evi her zaman sizin ve ailenizin yanında ve size yardım etmek için var gücüyle
                            çabalamaktadır.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="single-features">
                        <div className="signle-icon">
                            <i className="icofont icofont-paperclip"></i>
                        </div>
                        <h3>Yardım Başvurusu</h3>
                        <p>
                            Yardım Başvurusu yaparak durumunuzu bize iletin.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="single-features">
                        <div className="signle-icon">
                            <i className="icofont icofont-search-property"></i>
                        </div>
                        <h3>İhtiyaç Tespiti</h3>
                        <p>
                            İlgili birimlerimiz tarafından incelenerek ihtiyacınız tespit edilir.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="single-features last">
                        <div className="signle-icon">
                            <i className="icofont icofont-gift"></i>
                        </div>
                        <h3>İhtiyaçların Giderilmesi</h3>
                        <p>
                            İhtiyaçlarınızın tespitinden sonra vakit kaybetmeden bu ihtiyacın giderilmesi için işlemler başlatılır.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
}