




export default function Breadcumb({ setPage }) {

    return <div className="breadcrumbs overlay br1">
        <div className="container">
            <div className="bread-inner">
                <div className="row">
                    <div className="col-12">
                        <h2>Bağışçılarımız</h2>
                        <ul className="bread-list">
                            <li><a href="#" onClick={() => setPage("anasayfa")}>Anasayfa</a></li>
                            <li><i className="icofont-simple-right"></i></li>
                            <li className="active">Bağışçılarımız</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}