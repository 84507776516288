import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';


export const API = axios.create({
  baseURL: "https://ailedestekevi.com/api/api/",
  timeout: 15000
})
const root = ReactDOM.createRoot(document.getElementById('root'));
try {
  root.render(
    <App />
  );

} catch (err) {
  console.log(err)
}