




export default function HomeHelp({ page, setPage }) {

    return <section className="call-action overlay">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="content">
                        <h2 style={{ fontSize: "26px" }}>İhtiyaç Başvurusu / Yardımsever Başvurusu</h2>
                        <p>
                            Aşağıdaki butonları kullanarak ihtiyaç başvurusu yapabilir veya yardım etmek için yardımsever başvurusu da
                            yapabilirsiniz.
                        </p>
                        <div className="button yd-buttons">
                            <a href="#bizeUlasin" onClick={() => setPage("bizeUlasin")} className="btn yd-btn">İhtiyaç
                                Başvurusu<i className="fa fa-long-arrow-right"></i></a>
                            <a href="#bagisYap" onClick={() => setPage("bagisYap")} className="btn yd-btn">Yardımsever Başvurusu<i
                                className="fa fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}