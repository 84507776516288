import { useEffect, useState } from "react"
import axios from 'axios'








export default function Content() {
    useEffect(() => {
        let tempArr = []
        var kiyafetPhotos = document.getElementsByClassName("kiyafet-img-inner")
        Array.from(kiyafetPhotos).forEach(el => {
            var src = el.getAttribute("src");
            tempArr.push(src)
        })





        var kiyafetClicks = document.getElementsByClassName("kiyafet-img");
        Array.from(kiyafetClicks).forEach(el => {
            el.addEventListener("click", (e) => {
                var src = e.currentTarget.childNodes[0].getAttribute("src");
                document.body.style.overflow = "hidden";
                var slide = document.getElementById("kiyafet-slide");
                slide.style.display = "flex";
                var slideImg = document.getElementById("kiyafet-slide-img");
                slideImg.setAttribute("src", src);
            })
        })


        var kapatBtn = document.getElementsByClassName("kiyafet-slide-kapat");
        kapatBtn[0].addEventListener("click", (e) => {
            document.body.style.overflow = "unset";
            var slide = document.getElementById("kiyafet-slide");
            slide.style.display = "none";
        })


        var leftBtn = document.getElementsByClassName("kiyafet-slide-left");
        leftBtn[0].addEventListener("click", (e) => {
            var slideImg = document.getElementById("kiyafet-slide-img")
            var src = slideImg.getAttribute("src");
            var indexOfSrc = tempArr.indexOf(src);
            if (indexOfSrc == 0) {
                slideImg.setAttribute("src", tempArr[tempArr.length - 1])
            } else {
                slideImg.setAttribute("src", tempArr[indexOfSrc - 1])
            }
        })

        var rightBtn = document.getElementsByClassName("kiyafet-slide-right");
        rightBtn[0].addEventListener("click", (e) => {
            var slideImg = document.getElementById("kiyafet-slide-img")
            var src = slideImg.getAttribute("src");
            var indexOfSrc = tempArr.indexOf(src);
            if (indexOfSrc == tempArr.length - 1) {
                slideImg.setAttribute("src", tempArr[0])
            } else {
                slideImg.setAttribute("src", tempArr[indexOfSrc + 1])
            }
        })
    }, [])


    return <section className="pf-details section galeri-sect">
        <div className="container">
            <div className="row">
                <div className="col-12 donater-container kiyafet-container" id="kiyafet">
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (23)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (24)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (26)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (27)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (28)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (29)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (30)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (31)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (32)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (36)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (37)-min.jpg`)} className="kiyafet-img-inner" /></div>
                    <div className="kiyafet-img"><img src={require(`../../../kiyafet/kiyafet_yardim (38)-min.jpg`)} className="kiyafet-img-inner" /></div>
                </div>
            </div>
        </div>

    </section>
}