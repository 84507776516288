



export default function KiyafetContainer() {


    return <div id="kiyafet-slide">
        <div className="kiyafet-slide-kapat"><i className="icofont-close"></i> Kapat</div>
        <i className="icofont-arrow-left kiyafet-slide-left"></i>
        <i className="icofont-arrow-right kiyafet-slide-right"></i>
        <img id="kiyafet-slide-img" src="#" alt="#" />
    </div>
}