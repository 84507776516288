import baskanPhoto from '../../img/baskanx.jpg'
export default function Content({settings}) {

    const baskan = {
        photo: settings.baskan.photo,
        isim: settings.baskan.isim,
        title: settings.baskan.title,
        subtitle: settings.baskan.subtitle,
        text: settings.baskan.text,
        web: settings.baskan.web,
        mail: settings.baskan.mail,
        youtube: settings.baskan.youtube,
        twitter: settings.baskan.twitter,
        facebook: settings.baskan.facebook,
        instagram: settings.baskan.instagram
    }
    return <section className="pf-details section">
        <div className="container">
            <div className="row" style={{marginTop: "-80px"}}>
                <div className="col-12">
                    <div className="inner-content">
                        <div className="body-text" style={{ textJustify: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "8px" }}>
                                <img src={baskan.photo}
                                    style={{ width: "70%" }}
                                />
                                <div style={{ width: "225px", height: ".5px", backgroundColor: "black", marginTop: "5px" }}>
                                </div>
                                <h2>{baskan.isim}</h2>
                                <h5 style={{ fontWeight: "bold", color: "slategray", marginTop: "-3px" }}>{baskan.title}</h5>
                                <h6>{baskan.subtitle}</h6>
                            </div>
                            <p style={{fontWeight: "normal", fontSize: "1.3rem", color: "#2C2D3F", letterSpacing: ".75px",lineHeight: "30px", padding: "0px 40px", marginTop: "50px"}}>
                                {
                                    baskan.text
                                }                                
                            </p>
                            <div className="social-medias-baskan">
                                <i className="fa fa-globe" title={baskan.web}
                                    onClick={() => { window.open(baskan.web, "_blank") }}></i>
                                <i className="fa fa-envelope" title={baskan.mail}
                                    onClick={() => { window.open(`mailto:${baskan.mail}`) }}></i>
                                <i className="fa fa-youtube-play" title={baskan.youtube}
                                    onClick={() => { window.open(baskan.youtube, "_blank") }}></i>
                                <i className="fa fa-twitter" title={baskan.twitter}
                                    onClick={() => { window.open(baskan.twitter, "_blank") }}></i>
                                <i className="fa fa-facebook-f" title={baskan.facebook}
                                    onClick={() => { window.open(baskan.facebook, "_blank") }}></i>
                                <i className="fa fa-instagram" title={baskan.instagram}
                                    onClick={() => { window.open(baskan.instagram, "_blank") }}></i>
                            </div>
                            <div className="share">
                                <h4>Paylaş -</h4>
                                <ul>
                                    <li><a href="#" data-href="https://www.facebook.com/sharer/sharer.php?u="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-facebook-official"
                                            aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://twitter.com/intent/tweet?text=@BahcelievlerBel&url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://www.linkedin.com/sharing/share-offsite/?url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}><i
                                            className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}