import OwlCarousel from 'react-owl-carousel'




export default function Content() {

    return <section className="pf-details section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="inner-content">
                        <div className="image-slider">
                            <OwlCarousel className="pf-details-slider" style={{ height: "450px", overflow: "hidden" }} items={1} nav={true} loop={true} navText={['<span class="left-btn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>', '<span class="right-btn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>']} autoplay={true} smartSpeed={700} autoplayHoverPause={true} autoplaySpeed={1000}>
                                <img src={require("../../kiyafet/kiyafet_yardim (24)-min.jpg")} className='item' alt="#" style={{ objectFit: "fill" }} />
                                <img src={require("../../haber1.JPG")} className='item' alt="#" style={{ objectFit: "fill" }} />
                                <img src={require("../../haber3.JPG")} className='item' alt="#" style={{ objectFit: "fill" }} />
                                <img src={require("../../kiyafet/kiyafet_yardim (23)-min.jpg")} className='item' alt="#" style={{ objectFit: "fill" }} />
                                <img src={require("../../kiyafet/kiyafet_yardim (27)-min.jpg")} className='item' alt="#" style={{ objectFit: "fill" }} />
                                <img src={require("../../kiyafet/kiyafet_yardim (28)-min.jpg")} className='item' alt="#" style={{ objectFit: "fill" }} />
                            </OwlCarousel>
                        </div>
                        {/* <div className="date">
                            <ul>
                                <li><span>Hizmet Adı :</span> Kıyafet Yardımı</li>
                                <li><span>Süre :</span> Yok</li>
                                <li><span>Yer :</span> Bahçelievler Aile Destek Evi Merkezi</li>
                            </ul>
                        </div> */}
                        <div className="body-text">
                            <h3>Kıyafet Yardımı</h3>
                            <p>Bahçelievler Aile Destek Evi Birimi yeni kullanılmamış kıyafet bağışı yapan kişi kurum ve kuruluşlardan alınan yardımları ihtiyaç sahiplerine ulaştırmaktadır.
                                <br />
                                Bahçelievler Belediye başkanı Dr. Hakan BAHADIR öncülüğünde faaliyetlerini yürüten BADE'de kıyafet ihtiyaçları tamamen yeni ürünler ile karşılanmaktadır.
                                <br />
                                Her geçen gün hizmet içeriğini komşularımızın gereksinimlerine uygun olarak geliştiren BADE markalarla geliştirdikleri iş birlikleriyle nitelikli ürünleri bünyesine katmaya devam ediyor.
                                <br />
                                Müdürlüğümüz tarafından sosyo-ekonomik durumları araştırılıp uygunluğu tespit edilen aileler bu hizmetten faydalanmaktadır.
                                <br />
                                <br />
                                Desteğe ihtiyacı olan vatandaşlarımız <a href="tel:4440311" style={{ color: "#1a76d1" }}>444 0 311</a> numaralı çağrı merkezimizi arayarak başvuru yapabilir.
                            </p>
                            <div className="share">
                                <h4>Paylaş -</h4>
                                <ul>
                                    <li><a href="#" data-href="https://www.facebook.com/sharer/sharer.php?u="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-facebook-official"
                                            aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://twitter.com/intent/tweet?text=@BahcelievlerBel&url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}
                                        target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-href="https://www.linkedin.com/sharing/share-offsite/?url="
                                        onClick={(e) => { window.open(e.currentTarget.getAttribute("data-href") + window.location, "_blank"); }}><i
                                            className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}