import { useEffect, useState } from "react"
import axios from 'axios';
export default function Content({ settings }) {

    return <section className="contact-us section">
        <div className="container" style={{ backgroundColor: "transparent", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <iframe frameBorder="0" title="BaşvuruF" className="border-2 mt-1" scrolling="no" width="100%"
                src="https://ulakbel.bahcelievler.bel.tr/WebBasvuru#/" id="ubel"></iframe>
            <div className="contact-info">
                <div className="row">
                    <div className="col-lg-4 col-12 ">
                        <div className="single-info">
                            <i className="icofont icofont-ui-call"></i>
                            <div className="content">
                                <h3>{settings.bizeUlasin.numara}</h3>
                                <p>{settings.bizeUlasin.eposta}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 ">
                        <div className="single-info">
                            <i className="icofont-google-map"></i>
                            <div className="content">
                                <h3 style={{ marginTop: "-5px" }}>{settings.bizeUlasin.acikAdres}</h3>
                                <p>{settings.bizeUlasin.ilceIl}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 ">
                        <div className="single-info">
                            <i className="icofont icofont-wall-clock"></i>
                            <div className="content">
                                <h3>{settings.bizeUlasin.calismaSaatleri}</h3>
                                <p>{settings.bizeUlasin.kapaliGunler}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}