




export default function Breadcumb({ setPage }) {

    return <div className="breadcrumbs overlay br4">
        <div className="container">
            <div className="bread-inner">
                <div className="row">
                    <div className="col-12">
                        <h2>Kıyafet Yardımı</h2>
                        <ul className="bread-list">
                            <li><a href="#" onClick={() => setPage("anasayfa")}>Anasayfa</a></li>
                            <li><i className="icofont-simple-right"></i></li>
                            <li><a href="#">Galeri</a></li>
                            <li><i className="icofont-simple-right"></i></li>
                            <li className="active">Kıyafet Yardımı</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}