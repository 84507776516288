import Breadcumb from "../Components/KardesAile/Breadcumb";
import Content from "../Components/KardesAile/Content";
import Unavailable from "../Components/Unavailable";





export default function KardesAile({ page, setPage}) {






    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content />
    </>
}