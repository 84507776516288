


export default function FunFacts() {
    return <div id="fun-facts" className="fun-facts section overlay">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-fun">
                        <i className="icofont icofont-home"></i>
                        <div className="content">
                            <span className="counter" id="yardimEdilenAile">384</span>
                            <p>Yardım Edilen Aile Sayısı</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-fun">
                        <i className="icofont icofont-user-alt-3"></i>
                        <div className="content">
                            <span className="counter" id="yardimSeverVatandas">557</span>
                            <p>Yardımsever Vatandaş Sayısı</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-fun">
                        <i className="icofont-simple-smile"></i>
                        <div className="content">
                            <span className="counter" id="yardimEdilenVatandas">4379</span>
                            <p>Yardım Edilen Vatandaş Sayısı</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-fun">
                        <i className="icofont icofont-table"></i>
                        <div className="content">
                            <span className="counter" id="hizmetYili">15</span>
                            <p>Yıldır Hizmet Vermekteyiz</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}