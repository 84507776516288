




export default function VideoContainer() {


    return <div id="video-slide">
        <div className="video-slide-kapat"><i className="icofont-close"></i> Kapat</div>
        <i className="icofont-arrow-left video-slide-left"></i>
        <i className="icofont-arrow-right video-slide-right"></i>
        <video id="video-panel" controls autoPlay muted>
            <source id="video-slide-img" src="#" type="video/mp4" />
        </video>
    </div>
}