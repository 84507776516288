
import OwlCarousel from 'react-owl-carousel'
import Arrow from '../Arrow'


export default function OnlyMobileSlider() {
    return <section className="portfolio show-only-mobile" style={{ position: "relative" }}>
        <i className="mobileArrows fa fa-chevron-right chevron1"></i>
        <i className="mobileArrows fa fa-chevron-right chevron2"></i>
        <i className="mobileArrows fa fa-chevron-right chevron3"></i>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <Arrow style={{ position: "absolute", widtYh: "20px", height: "20px", right: "65px", top: "20px", overflow: "visible", transform: "translate(0%, 0) rotate(180deg)", zIndex: 999 }} />
                    <OwlCarousel style={{ overflow: "hidden", height: "300px" }} className="portfolio-slider owl-carousel" autoplay={true} autoplayTimeout={4000} margin={15} smartSpeed={300} autoplayHoverPause={true} loop={true} nav={true} dots={false} responsive={{
                        300: {
                            items: 1
                        },
                        480: {
                            items: 2
                        },
                        768: {
                            items: 2
                        },
                        1170: {
                            items: 4
                        }
                    }}>
                        <div className="single-pf item">
                            <img src={require("../../haber1.JPG")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/kiyafet_yardim (2).jpeg")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.06 (1).jpeg")} alt="#" /> 
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../newPhotos/WhatsApp Image 2023-10-23 at 15.25.06.jpeg")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../haber2.JPG")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../haber3.JPG")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../haber4.JPG")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../bade-banner1.png")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../bade-banner2.png")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../bade-banner3.png")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../BEL_4966.jpg")} alt="#" />
                        </div>
                        <div className="single-pf item">
                            <img src={require("../../bade.jpg")} alt="#" />
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    </section>
}