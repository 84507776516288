




export default function HomeNews({ page, setPage, setNews }) {

    const egitimHaber = {
        baslik: "Eğitim Yardımı Yapıldı",
        src: require("../../egitim-yardim.jpg"),
        icerik: "BADE öğrencileri unutmadı. Öğrenciler için eğitim yardımı yapıldı!",
        tarih: "13 Kasım 2019"
    }
    const kiyafetHaber = {
        baslik: "Kıyafet Yardımı Yapıldı",
        src: require("../../kiyafet-yardim.jpg"),
        icerik: "BADE kıyafet yardımı projesiyle ihtiyaç sahibi vatandaşlara ulaştı.",
        tarih: "13 Kasım 2019",
    }
    const yenilendiHaber = {
        baslik: "BADE Yenilendi!",
        src: require("../../haber3.png"),
        icerik: "BADE web sitesi güncellendi. Yeni arayüz ve tasarımıyla BADE hizmetinizde.",
        tarih: "16 Kasım 2023"
    }
    return <section className="blog section" id="blog">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>Bahçelievler Aile Destek Evi Haberleri</h2>
                        {/* <img src={require("../../img/section-img.png")} alt="#" /> */}
                        <p>
                            Bahçelievler Aile Destek evi tarafından güncel olarak hazırlanmış haberler
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-news">
                        <div className="news-head">
                            <img src={require("../../egitim-yardim.jpg")} alt="#" />
                        </div>
                        <div className="news-body">
                            <div className="news-content">
                                <div className="date">{egitimHaber.tarih}</div>
                                <h2>
                                    <a href="#egitimYardimiHaber" onClick={() => {
                                        setNews(egitimHaber)
                                        setPage("haber")
                                    }}>Eğitim Yardımı Yapıldı</a>
                                </h2>
                                <p className="text">
                                    BADE öğrencileri unutmadı. Öğrenciler için eğitim yardımı yapıldı!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-news">
                        <div className="news-head">
                            <img src={require("../../kiyafet-yardim.jpg")} alt="#" />
                        </div>
                        <div className="news-body">
                            <div className="news-content">
                                <div className="date">{kiyafetHaber.tarih}</div>
                                <h2>
                                    <a href="#kiyafetYardimiHaber" onClick={() => {
                                        setNews(kiyafetHaber)
                                        setPage("haber")
                                    }}>Kıyafet Yardımı Yapıldı</a>
                                </h2>
                                <p className="text">
                                    BADE kıyafet yardımı projesiyle ihtiyaç sahibi vatandaşlara ulaştı.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-news">
                        <div className="news-head" style={{ height: "235px" }}>
                            <img src={require("../../haber3.png")} alt="#" />
                        </div>
                        <div className="news-body">
                            <div className="news-content">
                                <div className="date">{yenilendiHaber.tarih}</div>
                                <h2>
                                    <a href="#badeYenilendiHaber" onClick={() => {
                                        setNews(yenilendiHaber)
                                        setPage("haber")
                                    }}>BADE Yenilendi!</a>
                                </h2>
                                <p className="text">
                                    BADE web sitesi güncellendi. Yeni arayüz ve tasarımıyla BADE hizmetinizde.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}