import axios from "axios";
import { useEffect, useState } from "react"
import HaberOzet from "../HaberOzet";

export default function Content({ setNews, setPage }) {
    const haberler = [
        {
            baslik: "BADE Yenilendi!",
            src: require("../../haber3.png"),
            icerik: "BADE web sitesi güncellendi. Yeni arayüz ve tasarımıyla BADE hizmetinizde.",
            tarih: "16 Kasım 2023"
        },
        {
            baslik: "BADE'de Kurban Bayramını Kutladık!",
            src: "https://igcdn.xyz/?token=41bb7a5fa43a924057c82a74fde50d1a1b5d761485e4f893c03312c346087264&time=1701103328&file=https%3a%2f%2fscontent.cdninstagram.com%2fv%2ft51.2885-15%2f356093906_928130334939260_8561118203713111137_n.jpg%3fstp%3ddst-jpg_e15%26efg%3deyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi41NDB4OTYwLnNkciJ9%26_nc_ht%3dscontent-sin6-2.cdninstagram.com%26_nc_cat%3d108%26_nc_ohc%3d6RGxshXLvlsAX92vIPL%26edm%3dALQROFkBAAAA%26ccb%3d7-5%26ig_cache_key%3dMzEzMzc3OTUwMTcyMzg4OTY2NA%253D%253D.2-ccb7-5%26oh%3d00_AfBKVlI8DFPZpcHF1hI3nfyFxt_5JPWSigbv6DTmYi__eA%26oe%3d6568B62C%26_nc_sid%3dfc8dfb",
            icerik: "Aile Destek Evimiz, bu bayram da ihtiyaç sahibi ailelerimizle hayırsever komşularımız arasında köprü olmaya devam ediyor.",
            tarih: "Haziran 2023",
            icerikVideo: require("../../video/haziran.mp4")
        },
        {
            baslik: "BADE'de coşkulu bayram kutlaması",
            src: "https://igcdn.xyz/?token=9861677591ae6856cba3947cfecdb9355b1d5566cf5e758af30fcba7715c2ff1&time=1701102797&file=https%3a%2f%2fscontent.cdninstagram.com%2fv%2ft51.2885-15%2f341904075_6281558265240684_7144222381296133272_n.jpg%3fstp%3ddst-jpg_e15%26_nc_ht%3dscontent.cdninstagram.com%26_nc_cat%3d100%26_nc_ohc%3d78w7vG4OkAMAX-x3R8k%26edm%3dAPs17CUBAAAA%26ccb%3d7-5%26oh%3d00_AfArBwGwXYC6ZAkcuawf8WMN7iE55FtqyP4DddU49uPr2A%26oe%3d656658CF%26_nc_sid%3d10d13b",
            icerik: "Bayramlıklarını dayanamayıp arefe günü giyip gezen çocuklara arife çiçeği denirdi. Ecdadımız da bayramlığı olmayan çocuklara bayramlık hediye eder, bir arife çiçeği olarak açmalarına vesile olurdu. Bahçelievler Aile Destek Evimiz ve arife çiçeklerimiz bayrama hazır...",
            icerikVideo: require("../../video/nisan.mp4"),
            tarih: "Nisan 2023"
        }
    ]
    return <section className="pf-details section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
                    {
                        haberler ? haberler.map((haber, i) => {
                            return <HaberOzet haber={haber} setNews={setNews} key={i} setPage={setPage} />
                        }) : null
                    }
                </div>
            </div>
        </div>
    </section>
}