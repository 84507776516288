import Breadcumb from "../Components/Baskan/Breadcumb";
import Content from "../Components/Baskan/Content";
import Unavailable from "../Components/Unavailable";



export default function Baskan({ page, setPage, settings }) {



    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content settings={settings} />
    </>

}