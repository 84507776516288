import Breadcumb from "../Components/BagisYap/Breadcumb";
import Content from "../Components/BagisYap/Content";
import Unavailable from "../Components/Unavailable";





export default function BagisYap({ page, setPage }) {

    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content />
    </>
}