import Breadcumb from "../Components/Galeri/MobilyaYardimGaleri/Breadcumb";
import Content from "../Components/Galeri/MobilyaYardimGaleri/Content";
import Unavailable from "../Components/Unavailable";







export default function GaleriMobilya({ page, setPage }) {



    return <>
        <Breadcumb page={page} setPage={setPage} />
        <Content />
    </>
}