

export default function HomeContact() {
    return <section className="appointment">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>Bahçelievler Aile Destek Evi<br />Bize Ulaşın</h2>
                        {/* <img src={require("../../img/section-img.png")} alt="#" /> */}
                        <p>
                            Aşağıdaki formu doldurarak bize ulaşabilirsiniz!
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <iframe frameBorder="0" title="BaşvuruF" className="border-2 mt-1" scrolling="no" width="100%"
                        src="https://ulakbel.bahcelievler.bel.tr/WebBasvuru#/" id="ubel"></iframe>
                </div>
            </div>
        </div>
    </section>
}